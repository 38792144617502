import TaskCard from './TaskCard.vue';
import {reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance} from 'vue';
import language from "./taskLanguage";

export default defineComponent ({
    name: "TaskList",
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj=reactive({
            pageListRef:null as any,
            canExecute:false,
            canStart:false,
            canStop:false,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: TaskCard,
                modelMethod: utils.Api.buildUrl("/task/pageData")
            }
        })
        onMounted(()=>{
        })

        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'status'==params.comboId){
                    return [{value:'false',label:proxy.$t('task.stop')},{value:'true',label:proxy.$t('task.start')}]
                }
            }
        })
        //执行
        const doExecute=async(taskIds:any)=>{
            utils.Tools.configBox({
                message:proxy.$t('task.executeNowTip'),
                sureFn: async ()=> {
                    const loading = proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                    let res = await utils.Api.executeTask({taskIds: taskIds});
                    loading.close();
                    if(res.result)utils.Tools.success({message: res.msg});
                }
            });

        }
        //批量立刻执行
        const executeHandler=async()=> {
            let selectRows = dataObj.pageListRef.getTbInst().getSelection();
            if (selectRows.length == 0) {
                utils.Tools.info({message: proxy.$t('task.selRowTip')});
                return;
            }
            let exist=selectRows.findIndex((item:any)=>{
                return item.F_STATUS=='false';
            });
            if(exist>-1){
                utils.Tools.info({message: proxy.$t('task.selWrongTip')});
            }else{
                selectRows = selectRows.map((item:any)=>{
                    return item.F_ID;
                });
                await doExecute(selectRows);
            }
        }
        //单挑立刻执行
        const executeNow=async(row:any)=>{
            dataObj.pageListRef.getTbInst().toggleRowSelection(row);
            await doExecute([row.F_ID]);
        }

        //启动或停止定时任务
        const operateStatus=(row:any)=>{
            utils.Tools.configBox({
                message:proxy.$t('task.executeSureTip'),
                sureFn: async ()=> {
                    const loading = proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                    let res = await utils.Api.changeTaskStatus({taskId: row.F_ID});
                    if(res.result){
                        utils.Tools.success({message: res.msg});
                        await dataObj.pageListRef.getTbInst().reloadGrid();
                    }
                    loading.close();
                }
            });

        }
        //表格加载完毕事件
        const gridLoaded=(res:any)=>{
            dataObj.canExecute=res.canExecute;
            dataObj.canStart=res.canStart;
            dataObj.canStop=res.canStop;
        }
        return{
            ...toRefs(dataObj),comboSelect,executeHandler,executeNow,operateStatus,gridLoaded
        }
    }
});